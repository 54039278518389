import React from "react";
import { Create } from "react-admin";
import TenantForm from "./TenantForm";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import Link from "@material-ui/core/Link";

const TenantCreate = (props) => (
  <div>
    <Breadcrumbs aria-label="breadcrumb" style={{
      borderRadius: 5,
      background: "white",
      padding: 12,
      border: "1px solid #e0e0e3",
      //marginLeft: 16,
      //marginRight: 30,
      marginTop: 12,
    }}>
      <Link color="inherit" href="/tenants">
        Tenants
      </Link>
      <Link style={{ fontWeight: "bold" }} color="textPrimary" aria-current="page" disabled>
        Tenant
      </Link>
    </Breadcrumbs>
    <Create {...props} component="div">
      <TenantForm />
    </Create>
  </div>
);

export default TenantCreate;
