import React, { useContext, useEffect } from "react";
import {
  FormWithRedirect,
  DateInput,
  TextInput,
  SelectInput,
  SaveButton,
  DeleteButton,
  required,
  minLength,
  maxLength,
  minValue,
  maxValue,
  number,
  email,
  useTranslate,
  ReferenceInput,
  AutocompleteInput,
  Toolbar,
} from "react-admin";
import { Typography, Box } from "@material-ui/core";
import { IntlPhoneInput } from "../components/IntlPhoneInput";
import { Card, CardContent } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import CustomSaveButton from "./CustomSaveButton";
import ImageUploader from "../components/ImageUploader";

const validateName = [required(), minLength(2), maxLength(125)];
const validateEmail = [required(), email()];

const useStyles = makeStyles((theme) => ({
  formGrid: {
    paddingTop: "0!important",
    paddingBottom: "0!important",
  },
  formInput: {
    marginTop: "0!important",
    marginBottom: "0!important",
  },
  textBold: {
    fontWeight: "bold",
  },
}));

const IbeSitesForm = (props) => {
  const classes = useStyles();
  const translate = useTranslate();
  const { source, ...rest } = props;
  const [logoImage, setlogoImage] = React.useState(null);
  const [bannerImage1, setBannerImage1] = React.useState(null);
  const [bannerImage2, setBannerImage2] = React.useState(null);
  const [bannerImage3, setBannerImage3] = React.useState(null);

  useEffect(() => {
    if (props.record.logo_url != null)
      setlogoImage([{ data_url: props.record.logo_url }]);
    if (props.record.banner_img_url_1 != null)
      setBannerImage1([{ data_url: props.record.banner_img_url_1 }]);
    if (props.record.banner_img_url_2 != null)
      setBannerImage2([{ data_url: props.record.banner_img_url_2 }]);
    if (props.record.banner_img_url_3 != null)
      setBannerImage3([{ data_url: props.record.banner_img_url_3 }]);
  }, []);


  return (
    <FormWithRedirect
      {...props}
      render={(formProps) => (
        <Card>
          <form>
            <CardContent>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Typography
                    variant="h6"
                    className={classes.textBold}
                    gutterBottom
                  >
                    Tenant
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6} className={classes.formGrid}>
                  <ReferenceInput
                    className={classes.formInput}
                    label="Tenant"
                    source="tenant_id"
                    reference="tenants"
                    fullWidth
                    resettable
                  >
                    <SelectInput InputProps="id" optionText="name" />
                  </ReferenceInput>
                </Grid>

                <Grid item xs={12}>
                  <Typography
                    variant="h6"
                    className={classes.textBold}
                    gutterBottom
                  >
                    Basic info
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6} className={classes.formGrid}>
                  <TextInput
                    className={classes.formInput}
                    fullWidth
                    source="namespace"
                    validate={validateName}
                    label="Namespace"
                  />
                </Grid>
                <Grid item xs={12} sm={6} className={classes.formGrid}>
                  <TextInput
                    className={classes.formInput}
                    fullWidth
                    source="name"
                    label="Company Name"
                  />
                </Grid>
                <Grid item xs={12}>
                  <Card>
                    <CardContent>
                      <Typography
                        style={{
                          marginTop: 10,
                          color: "#000000",
                          opacity: "0.5",
                        }}
                        variant="caption"
                        display="block"
                        gutterBottom
                      >
                        Logo Image
                      </Typography>
                      <ImageUploader
                        maxNumber={1}
                        images={logoImage}
                        setImages={setlogoImage}
                      />
                    </CardContent>
                  </Card>
                </Grid>
                <Grid item xs={12} sm={6} className={classes.formGrid}>
                  <TextInput
                    className={classes.formInput}
                    fullWidth
                    source="fb_link"
                    label="Facebook Link"
                  />
                </Grid>

                <Grid item xs={12}>
                  <Typography
                    variant="h6"
                    className={classes.textBold}
                    gutterBottom
                  >
                    Banner Title
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6} className={classes.formGrid}>
                  <TextInput
                    className={classes.formInput}
                    fullWidth
                    source="banner_title_1"
                    label="Banner title 1"
                  />
                </Grid>
                <Grid item xs={12} sm={6} className={classes.formGrid}>
                  <TextInput
                    className={classes.formInput}
                    fullWidth
                    source="banner_title_2"
                    label="Banner title 2"
                  />
                </Grid>
                <Grid item xs={12} sm={6} className={classes.formGrid}>
                  <TextInput
                    className={classes.formInput}
                    fullWidth
                    source="banner_title_3"
                    label="Banner title 3"
                  />
                </Grid>
                <Grid item xs={12}>
                  <Typography
                    variant="h6"
                    className={classes.textBold}
                    gutterBottom
                  >
                    Banner Content
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={12} className={classes.formGrid}>
                  <TextInput
                    className={classes.formInput}
                    fullWidth
                    source="banner_content_1"
                    label="Banner content 1"
                  />
                </Grid>
                <Grid item xs={12} sm={12} className={classes.formGrid}>
                  <TextInput
                    className={classes.formInput}
                    fullWidth
                    source="banner_content_2"
                    label="Banner content 2"
                  />
                </Grid>
                <Grid item xs={12} sm={12} className={classes.formGrid}>
                  <TextInput
                    className={classes.formInput}
                    fullWidth
                    source="banner_content_3"
                    label="Banner content 3"
                  />
                </Grid>
                <Grid item xs={12}>
                  <Typography
                    variant="h6"
                    className={classes.textBold}
                    gutterBottom
                  >
                    Banner Images
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Card>
                    <CardContent>
                      <Typography
                        style={{
                          marginTop: 10,
                          color: "#000000",
                          opacity: "0.5",
                        }}
                        variant="caption"
                        display="block"
                        gutterBottom
                      >
                        Banner Image 1
                      </Typography>
                      <ImageUploader
                        maxNumber={1}
                        images={bannerImage1}
                        setImages={setBannerImage1}
                      />
                    </CardContent>
                  </Card>
                </Grid>
                <Grid item xs={12}>
                  <Card>
                    <CardContent>
                      <Typography
                        style={{
                          marginTop: 10,
                          color: "#000000",
                          opacity: "0.5",
                        }}
                        variant="caption"
                        display="block"
                        gutterBottom
                      >
                        Banner Image 2
                      </Typography>
                      <ImageUploader
                        maxNumber={1}
                        images={bannerImage2}
                        setImages={setBannerImage2}
                      />
                    </CardContent>
                  </Card>
                </Grid>

                <Grid item xs={12}>
                  <Card>
                    <CardContent>
                      <Typography
                        style={{
                          marginTop: 10,
                          color: "#000000",
                          opacity: "0.5",
                        }}
                        variant="caption"
                        display="block"
                        gutterBottom
                      >
                        Banner Image 3
                      </Typography>
                      <ImageUploader
                        maxNumber={1}
                        images={bannerImage3}
                        setImages={setBannerImage3}
                      />
                    </CardContent>
                  </Card>
                </Grid>
              </Grid>
            </CardContent>
            <Toolbar>
              <Box display="flex" justifyContent="space-between" width="100%">
                <CustomSaveButton
                  {...formProps}
                  images={[
                    { name: "logo_url", data: logoImage },
                    { name: "banner_img_url_1", data: bannerImage1 },
                    { name: "banner_img_url_2", data: bannerImage2 },
                    { name: "banner_img_url_3", data: bannerImage3 }
                  ]}
                />
                {/* {formProps.record.id != undefined ?
                  <DeleteButton record={formProps.record} />
                  : null} */}
              </Box>
            </Toolbar>
          </form>
        </Card>
      )}
    />
  );
};

export default IbeSitesForm;
