import React from "react";
import { Edit } from "react-admin";
import TenantForm from "./TenantForm";
import { useTranslate } from "react-admin";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import Link from "@material-ui/core/Link";

const CompanyTitle = ({ record }) => {
  const translate = useTranslate();
  return (
    <span>
      {translate("ra.label.tenants")} {record ? `${record.name}` : ""}
    </span>
  );
};

const TenantGroupEdit = (props) => (
  <div>
    <Breadcrumbs aria-label="breadcrumb" style={{
      borderRadius: 5,
      background: "white",
      padding: 12,
      border: "1px solid #e0e0e3",
      //marginLeft: 16,
      //marginRight: 30,
      marginTop: 12,
    }}>
      <Link color="inherit" href="/tenant_groups">
        Tenants Group
      </Link>
      <Link style={{ fontWeight: "bold" }} color="textPrimary" aria-current="page" disabled>
        Tenant Group
      </Link>
    </Breadcrumbs>
    <Edit title={<CompanyTitle />} {...props} component="div">
      <TenantForm />
    </Edit>
  </div>
);

export default TenantGroupEdit;
