import gql from 'graphql-tag';

const GET_LIST_USERS = gql`
{
  created_at
  email
  first_name
  id
  last_name
  is_deleted
  mapped_id
  role
  e_user_roles {
    id
    comment
  }
  status
  e_user_status {
    id
    comment
  }
  tenant_id
  tenant_group_id
  updated_at
  tenant {
    address_1
    address_2
    city
    country
    e_country {
      comment
      id
    }
    locale_id
    locale {
      country
      currency_code
      decimal_seperator
      id
      precision
      thousand_seperator
    }
    created_at
    email
    id
    name
    phone_no
    region
    status
    updated_at
    zip_code
  }
}
`;

const GET_ONE_USERS = gql`
{
  created_at
  email
  first_name
  id
  last_name
  role
  is_deleted
  mapped_id
  status
  tenant_id
  tenant_group_id
  updated_at
  tenant {
    address_1
    address_2
    city
    country
    e_country {
      comment
      id
    }
    locale_id
    locale {
      country
      currency_code
      decimal_seperator
      id
      precision
      thousand_seperator
    }
    created_at
    email
    id
    name
    phone_no
    region
    status
    updated_at
    zip_code
  }
}
`;

export{GET_LIST_USERS,GET_ONE_USERS}
