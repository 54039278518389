import React, { useEffect, useState, useMemo } from "react";
import {
    FormWithRedirect,
    TextInput,
    SelectInput,
    required,
    useTranslate,
    ReferenceInput,
    Toolbar,
    FunctionField,
} from "react-admin";
import { Typography, Box, Chip } from "@material-ui/core";
import { Card, CardContent } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import ImageUploader from "../components/ImageUploader";
import { useNotify, useRedirect } from "react-admin";
import CustomSaveButton from './CustomSaveButton';
import InvoiceInfo from './DisbursementInvoiceList';
import DepositInfo from './DisbursementDepositList';
import { formatter } from "../../utils/formatter";

const useStyles = makeStyles((theme) => ({
    formGrid: {
        paddingTop: "0!important",
        paddingBottom: "0!important",
    },
    formInput: {
        marginTop: "0!important",
        marginBottom: "0!important",
    },
    textBold: {
        fontWeight: "bold",
    },
}));

const DisbursementForm = (props) => {
    const classes = useStyles();
    const notify = useNotify();
    const translate = useTranslate();
    const { source, ...rest } = props;
    const [disbursement, setDisbursement] = React.useState(null);
    const [images, setImages] = React.useState([]);

    useEffect(() => {
        setDisbursement(props.record);
        if (props.record != null) {
            if (props.record.images_url == null && props.record.image_url != null && props.record.image_url != "") {
                props.record.images_url = [props.record.image_url];
            }

            setImages(props.record.images_url?.map(element => {
                return { data_url: element }
            }));
        }
    }, []);

    return (
        <FormWithRedirect
            {...props}
            render={(formProps) => (
                <Card>
                    <form>
                        <CardContent>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <Typography
                                        variant="h6"
                                        className={classes.textBold}
                                        gutterBottom
                                    >
                                        Disbursement Info
                                    </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <InvoiceInfo disbursement={disbursement} />
                                </Grid>
                                <Grid item xs={12}>
                                    <DepositInfo disbursement={disbursement} />
                                </Grid>
                                <Grid item xs={12}>
                                    <Card><CardContent>
                                        <Typography style={{ marginTop: 10, color: "#000000", opacity: "0.5" }} variant="caption" display="block" gutterBottom>
                                            Bank Name
                                        </Typography>
                                        <FunctionField sortBy="bank_name" label="Bank Name" render={(record) => record.bank_name } />
                                    </CardContent></Card>
                                </Grid>
                                <Grid item xs={12}>
                                    <Card><CardContent>
                                        <Typography style={{ marginTop: 10, color: "#000000", opacity: "0.5" }} variant="caption" display="block" gutterBottom>
                                            Bank Account No
                                        </Typography>
                                        <FunctionField sortBy="bank_account_no" label="Bank Account No" render={(record) => record.bank_account_no } />
                                    </CardContent></Card>
                                </Grid>
                                <Grid item xs={12}>
                                    <Card><CardContent>
                                        <Typography style={{ marginTop: 10, color: "#000000", opacity: "0.5" }} variant="caption" display="block" gutterBottom>
                                            Received Amount
                                        </Typography>
                                        <FunctionField sortBy="received_amount" label="Received Amount" render={(record) => formatter.formatMoney(record.received_amount, record.currency, 2)} />
                                    </CardContent></Card>
                                </Grid>
                                <Grid item xs={12}>
                                    <Card><CardContent>
                                        <Typography style={{ marginTop: 10, color: "#000000", opacity: "0.5" }} variant="caption" display="block" gutterBottom>
                                            Charges
                                        </Typography>
                                        <FunctionField sortBy="charges" label="Charges" render={(record) => formatter.formatMoney(record.charges, record.currency, 2)} />
                                    </CardContent></Card>
                                </Grid>
                                <Grid item xs={12}>
                                    <Card><CardContent>
                                        <Typography style={{ marginTop: 10, color: "#000000", opacity: "0.5" }} variant="caption" display="block" gutterBottom>
                                            Payable Amount
                                        </Typography>
                                        <FunctionField sortBy="payable_amount" label="Payable Amount" render={(record) => formatter.formatMoney(record.payable_amount, record.currency, 2)} />
                                    </CardContent></Card>
                                </Grid>
                                <Grid item xs={12} sm={12} className={classes.formGrid}>
                                    <ReferenceInput
                                        className={classes.formInput}
                                        fullWidth
                                        label="Status"
                                        source="status"
                                        reference="e_disbursement_status"
                                        validate={[required()]}
                                    >
                                        <SelectInput optionText="comment" />
                                    </ReferenceInput>
                                </Grid>
                                <Grid item xs={12}>
                                    <Card><CardContent>
                                        <Typography style={{ marginTop: 10, color: "#000000", opacity: "0.5" }} variant="caption" display="block" gutterBottom>
                                            Images
                                        </Typography>
                                        <ImageUploader
                                            maxNumber={1}
                                            images={images}
                                            setImages={setImages}
                                        />
                                    </CardContent></Card>
                                </Grid>
                                <Grid item xs={12} sm={12} className={classes.formGrid}>
                                    <TextInput
                                        className={classes.formInput}
                                        fullWidth
                                        source="remark"
                                        label="Remark"
                                    />
                                </Grid>
                            </Grid>
                        </CardContent>
                        <Toolbar>
                            <Box display="flex" justifyContent="space-between" width="100%">
                                {/* <SaveButton {...formProps} /> */}
                                <CustomSaveButton {...formProps} images={images} />
                            </Box>
                        </Toolbar>
                    </form>
                </Card>
            )}
        />
    );
};

export default DisbursementForm;
